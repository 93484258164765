import { initialState } from "./state";
import { cartActionsTypes } from "./actions";
import produce from 'immer'

export const cart = (state = initialState, action) => 
produce(state, draft => {

  const { type, payload } = action;
  switch (type) {
    
    case cartActionsTypes.ADD_ITEM: {
      const { product } = payload;
      const newItem = {
        product,
      };
      return state.concat(newItem);
    }

    case cartActionsTypes.REMOVE_ITEM: {
      let values = action.payload;

      draft.items = values.items;
      draft.cartTotal = values.cartTotal;
      break;
    }

    case cartActionsTypes.UPDATE_QUANTITY: {
      let values = action.payload;

      draft.items[values.itemIndex].productQuantity = values.quantity;
      draft.cartTotal = values.sum;
      break;
    }

    case cartActionsTypes.SET_CART_DATA: {

      let initialCart = [
        {
          productName: "Cell Phone",
          productDiscription: "This is a basic phone.",
          productPrice: "25.25",
          productQuantity: 1,
          productId: 0,
        },
        {
          productName: "Computer Glasses",
          productDiscription: "These glasses have blue light filtering technology.",
          productPrice: "50.25",
          productQuantity: 1,
          productId: 1,
        },
        {
          productName: "Bluetooth Computer Mouse",
          productDiscription: "This is a basic bluetooth mouse.",
          productPrice: "11.25",
          productQuantity: 1,
          productId: 2,
        },
      ];

      draft.items = initialCart;
      draft.cartTotal = 86.75;
      break;
    }

    default: {
      return state;
    }
  }
});