import React, { useState } from "react";
import PersonalCard from "./portfolioSection/PersonalCard";
import "devicon/devicon.min.css";
import Skills from "./portfolioSection/Skills";
import About from "./portfolioSection/About";

function Portfolio() {
  const [selection, setSelection] = useState(true);

  const getPortfolioSelection = (value) => {
    setSelection(value);
  };

  return (
    <React.Fragment>
      <div className="col-12 d-flex">
        <PersonalCard getSelection={getPortfolioSelection} />
        {selection ? <About /> : <Skills />}
      </div>
    </React.Fragment>
  );
}

export default Portfolio;
