import React, { useEffect } from "react";
import NavBar from "./NavBar";
import Portfolio from "./Portfolio";
import Projects from "./Projects";
import Contact from "./Contact";
import Home from "./Home";
import AOS from "aos";
import "aos/dist/aos.css";

const LandingPage = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <React.Fragment>
      <section
        id="home"
        className="d-flex height-fixed align-items-center justify-content-center"
      >
        <Home />
      </section>
      <span className="col-12">
        <NavBar />
      </span>
      <section
        id="portfolio"
        className="height-fixed p-5 section-divider-color"
      >
        <div className="container">
          {/*
          <div
            data-aos="zoom-out"
            data-aos-once="true"
            className="col-12 text-center pt-5"
          >
            <span className="landing-tangerine">Portfolio</span>
          </div>
          <div
            data-aos="zoom-out"
            data-aos-once="true"
            className="col-12 text-center pb-3"
          >
            <span className="text-white text-shadow">___________</span>
          </div>
          */}
          <div>
            <Portfolio />
          </div>
        </div>
      </section>
      <section id="projects" className="height-fixed p-5">
        <div className="container">
          {/*
          <div
            data-aos="zoom-out"
            data-aos-once="true"
            className="text-center pt-5"
          >
            <span className="landing-tangerine">Projects</span>
          </div>
          <div
            data-aos="zoom-out"
            data-aos-once="true"
            className="col-12 text-center pb-3"
          >
            <span className="text-white text-shadow">___________</span>
          </div>
          */}
          <div>
            <Projects />
          </div>
        </div>
      </section>
      <section id="contact" className="height-fixed p-5 section-divider-color">
        <div className="container">
          {/*
          <div
            data-aos="zoom-out"
            data-aos-once="true"
            className="text-center pt-5"
          >
            <span className="landing-tangerine">Contact</span>
          </div>
          <div
            data-aos="zoom-out"
            data-aos-once="true"
            className="col-12 text-center pb-3"
          >
            <span className="text-white text-shadow">___________</span>
          </div>
          */}
          <div>
            <Contact />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default LandingPage;
