import React from "react"; //,{ useEffect }
import { useForm } from "react-hook-form";
import { actions } from "../../store/store"
import { useSelector } from "react-redux";

const CartItem = ({ item }) => {

  const items = useSelector((state) => state.cart.items);

  const {
    register,
    handleSubmit,
    watch,
    //formState: { errors },
  } = useForm();

  //==========( CONTENT )

  const watchItemQuantity = watch("productQuantity");

  const onUpdateCartClicked = () => {
    actions.updateQuantity(item, watchItemQuantity, items)
  };

  const onRemoveClicked = () => {
    actions.removeItem(item, items);
  };

  return (
    <React.Fragment>
      <div className="pt-2 justify-content-center col-6">
        <form
          onSubmit={handleSubmit}
        >
          <div className="card col-12 p-2 justify-content-center">
            <div className="row">
              <div className="col-12">
                <div className="col-12">
                  <h4 className="product-name p-2">
                    <strong>{item.productName}</strong>
                  </h4>
                </div>
                <div className="col-12 text-center">
                  <h5>
                    <small>{item.productDiscription}</small>
                  </h5>
                </div>
                <hr/>
              </div>

              <div className="col-12">

                <div className="col-12 d-flex pt-2 justify-content-center">
                  <div className="col-4 justify-content-center text-end pt-2 p-2">
                    <h6>
                      <strong>
                        {`$${item.productPrice}`}
                        <span className="text-muted">x</span>
                      </strong>
                    </h6>
                  </div>

                  <div className="col-2 justify-content-center">
                    <input
                      {...register("productQuantity")}
                      placeholder="Quantity"
                      type="text"
                      className="form-control input-sm"
                      defaultValue={item.productQuantity}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex pt-2 p-2 justify-content-center">
                  <div className="col-4 d-flex justify-content-center">
                    <button
                      onClick={() => onUpdateCartClicked()}
                      type="button"
                      className="btn btn-warning btn-sm btn-block"
                    >
                      Update Quantity
                    </button>
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <button
                      onClick={() => onRemoveClicked()}
                      type="button"
                      className="btn btn-danger btn-sm btn-block"
                    >
                      Remove
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default React.memo(CartItem);
