import React from "react";
import { Grid, Card, CardContent, Button } from "@material-ui/core";
import kitchmet from "../../assets/images/kitchmet-landing.png";
import project from "../../assets/images/project.png";
import hecc from "../../assets/images/hecc.png";

function Projects() {
  // TODO: Map out each card.
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>

          <Card
            className="card-style shadow text-white text-shadow"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <img alt="..." className="card-img-top card-image" src={kitchmet} />
            <CardContent className="p-3 text-center">
              <h5 className="card-title font-weight-bold font-size-lg">
                Kitchmet
              </h5>
              <p className="card-text">React.JS / Asp.Net / SQL Server</p>

              <Button
                className="mui-btn btn-style-1 text-white text-shadow"
                variant="contained"
                href="https://kitchmet.azurewebsites.net/"
              >
                View site
              </Button>
            </CardContent>
          </Card>

        </Grid>
        <Grid item xs={12} sm={6} md={4}>

          <Card
            className="card-style shadow text-white text-shadow"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <img alt="..." className="card-img-top card-image" src={hecc} />
            <CardContent className="p-3 text-center">
              <h5 className="card-title font-weight-bold font-size-lg">
                HECC
              </h5>
              <p className="card-text">React.JS / Asp.Net / SQL Server</p>
              <Button
                className="mui-btn btn-style-1 text-white text-shadow"
                variant="contained"
              >
                View Projects
              </Button>
            </CardContent>
          </Card>

        </Grid>
        <Grid item xs={12} sm={6} md={4}>

          <Card
            className="card-style shadow text-white text-shadow"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <img alt="..." className="card-img-top card-image" src={project} />
            <CardContent className="p-3 text-center">
              <h5 className="card-title font-weight-bold font-size-lg">
                Your project...
              </h5>
              <p className="card-text">Lets connect</p>
              <Button
                className="mui-btn btn-style-1 text-white text-shadow"
                variant="contained"
              >
                Contact
              </Button>
            </CardContent>
          </Card>

        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Projects;
