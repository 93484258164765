import "./checkout.css";
import CartItem from "./CartItem";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { actions } from "../../store/store"
//import { setCartData, test } from "../../store/actions"
//import PaymentInterface from "./PaymentInterface";
//import HandleNav from "./HandleNav";

const ShoppingCart = () => {

  //==========( STATE HOOKS )

  const navigate = useNavigate();
  const [ displayItemList, setDisplayItemList ] = useState();

  const items = useSelector((state) => state.cart.items);
  const cartTotal = useSelector((state) => state.cart.cartTotal);
  //==========( EFFECT HOOKS )

  useEffect(() => {
    //console.log("setDisplayItemList");
    if (items) {
      let mappedItems = items.map(mapCartItem);
      setDisplayItemList(mappedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  //==========( MEMO )

/*
  useMemo(() => {
    console.log("setCartTotal");
    //calculateTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    let sum = calculateTotal(items);
    actions.updateCartTotal(sum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
*/

  //==========( MAP CART ITEMS )

  const mapCartItem = (item, index) => {
    return (
      <React.Fragment key={`item${index}`}>
        <CartItem
          item={item}
        />
      </React.Fragment>
    );
  };

  //==========( NAVIGATION )

  // TODO: Break out into single component
  const handleNav = (e) => {
    switch (e.target.id) {
      case "checkout":
        navigate(`/payment/${cartTotal}`);
        break;
      case "continue":
        navigate("/shoppingCart");
        break;
      case "update":
        navigate("/update");
        break;
      default:
        break;
    }
  };

  //==========( UPDATE CART )

  const addItemsToCart = () => {
    //console.log("addItemsToCart")
    actions.setCartData();
  };

  //==========( CONTENT )

  const content = (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-xs-8">
            <div className="panel panel-info">

              <div className="panel-heading pb-4 pt-4">
                <div className="panel-title">
                  <div className="row ">
                    <div className="col-xs-6">
                      <h5>
                        Note: This project ia a work in progress. A Landing page for personal projects is in the works and will house work, notes, etc.
                      </h5>
                      <h4 className="pt-4">
                        <span className="glyphicon glyphicon-shopping-cart"></span>{" "}
                        Shopping Cart
                      </h4>
                    </div>
                    <div className="col-xs-6">
                      <button
                        id="continue"
                        onClick={handleNav}
                        type="button"
                        className="btn btn-primary btn-sm btn-block"
                      >
                        <span className="glyphicon glyphicon-share-alt"></span>{" "}
                        Continue shopping
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-body">
                <div className="col-12 justify-content-center">
                  {displayItemList}  
                </div>        
                <div className="row">
                  <div className="text-center">
                    <div className="col-xs-9">
                      <h6 className="text-right pt-4">Added items?</h6>
                    </div>
                    <div className="col-xs-3">
                      <button
                        id="update"
                        onClick={addItemsToCart}
                        type="button"
                        className="btn btn-default btn-sm btn-block"
                      >
                        Update cart
                      </button>
                    </div>
                  </div>
                </div>
               

              </div>
              <div className="panel-footer">
                <div className="row text-center">
                  <div className="col-xs-9">
                    <h4 className="text-right">
                      Total <strong>${cartTotal}</strong>
                    </h4>
                  </div>
                  <div className="col-xs-3">
                    <button
                      id="checkout"
                      onClick={handleNav}
                      type="button"
                      className="btn btn-success btn-block"
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4 text-center">
        <h4>
          <strong>Press update cart to add simulated items to cart</strong>
        </h4>
      </div>
    </React.Fragment>
  );

  return content;
};

export default React.memo(ShoppingCart);
