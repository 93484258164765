//import { lazy } from "react";
import ShoppingCart from "../checkout/ShoppingCart";
import PaymentInterface from "../checkout/PaymentInterface";
//import NavBar from "../portfolio/NavBar";
import LandingPage from "../portfolio/LandingPage";

const routeList = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/payment/:total",
    element: <PaymentInterface />,
  },
  {
    path: "/shoppingcart",
    element: <ShoppingCart />,
  },
];

export default routeList;
