import React from "react";
import { Card } from "@material-ui/core";

function Skills() {
  return (
    <React.Fragment>
      <Card
        data-aos="flip-right"
        data-aos-once="true"
        data-aos-easing="ease-out"
        className="col-8 p-4 m-2 card-style text-shadow shadow"
      >
        <div className="p-2">
          <div className="pb-4 font-weight-bold text-white">
            Professional experience with:
          </div>
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg"
            alt=""
          />
          <img 
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg" 
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dotnetcore/dotnetcore-original.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/microsoftsqlserver/microsoftsqlserver-plain-wordmark.svg"
            alt=""
          />
        </div>
        <div className="p-2">
          <div className="pb-4 font-weight-bold text-white">
            Development environment:
          </div>
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original-wordmark.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/visualstudio/visualstudio-plain-wordmark.svg"
            alt=""
          />
        </div>
        <div className="p-2">
          <div className="pb-4 font-weight-bold  text-white">
            Development tools:
          </div>
          <img 
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original-wordmark.svg"
            alt=""
          />
          <img 
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original-wordmark.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original-wordmark.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/slack/slack-original-wordmark.svg"
            alt=""
          />
          <img
            className="dev-img-lg"
            src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/trello/trello-plain-wordmark.svg"
            alt=""
          />
        </div>
      </Card>
    </React.Fragment>
  );
}

export default Skills;
