import { calculateTotal } from "../../components/checkout/toolFunctions";
//==========( ACTIONS )

export const cartActionsTypes = {
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  UPDATE_QUANTITY: "UPDATE_QUANTITY",
  SET_CART_DATA: "SET_CART_DATA",
};

export const addItem = (product) => (dispatch) => {
  dispatch({
    type: cartActionsTypes.ADD_ITEM,
    payload: { product },
  });
};

export const removeItem = (item, items) => (dispatch) =>{

  let newItems = items;

  let filterItems = (anItem) =>{
    if(anItem.productId !== item.productId){
      return anItem;
    }
    return;
  }
  let filteredItems = newItems.filter(filterItems);

  let sum = calculateTotal(filteredItems);

  dispatch({
  type: cartActionsTypes.REMOVE_ITEM,
  payload: {
    items: filteredItems, 
    cartTotal: sum
  }
  })
};

export const updateQuantity = (item, quantity, items) => (dispatch) =>{
 
  let itemIndex = null;

  for (let index = 0; index < items.length; index++) {
    if(items[index].productId === item.productId){
      itemIndex = index;
      break;
    } 
  }

  let sum = calculateTotal(items, itemIndex, quantity);

  dispatch({
    type: cartActionsTypes.UPDATE_QUANTITY,
    payload: {
      sum,
      itemIndex,
      quantity,
    }
    })
};

//==========( THUNKS )

export const setCartData = (cart) => (dispatch) => {
  console.log("setCartData");
  // This will be an ASYNC API call.
  /*
   export const setCartData = (cart) => async (dispatch) => {
   let api call = await apiClient.get(/html)
   let mappedCart = cart.map((respponse)=>({
   ...response
   item:data
  }));
  */
  dispatch({
    type: cartActionsTypes.SET_CART_DATA,
    //payload: cart,
  });
};
