import React from "react";
import { Button } from "@material-ui/core";
import { scroller } from "react-scroll";

function Home() {
  const scrollToSection = () => {
    scroller.scrollTo("portfolio", {
      duration: 800,
      delay: 0,
    });
  };

  return (
    <React.Fragment>
      <div
        data-aos="fade"
        data-aos-duration="3000"
        data-aos-once="true"
        className="justify-content-center text-center cab-bg-image"
      >
        <div className="name-header mt-4 pt-5">Thomas McGarvey</div>
        <div className="title-header ">~ Software Engineer</div>
        <div className="p-4"></div>
        <div className="col-12">
          <Button
            onClick={scrollToSection}
            className="mt-5 mui-btn text-white text-capitalize btn-style-1"
            variant="contained"
          >
            Learn about me
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home;
