import React from "react";
import { IconButton, Card, List, ListItem, Tooltip } from "@material-ui/core";
import avatar from "../../../assets/images/thomas.jpg";
import { faFire, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PersonalCard(props) {
  const onBtnBlick = (e) => {
    let select = true;
    if (e.target.id === "skills") {
      select = false;
    }
    props.getSelection(select);
  };

  return (
    <React.Fragment>
      <Card
        data-aos="flip-down"
        data-aos-once="true"
        data-aos-easing="ease-out"
        className="col-4 p-4 m-2 justify-content-center align-items-center card-style shadow"
      >
        <div className="text-center justify-content-center">
          <div className="rounded card-style-5 card-highlight">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div className="d-flex pt-3 avatar-icon-wrapper m-0 d-90 justify-content-center ">
                <div className="rounded-circle overflow-hidden avatar">
                  <img alt="..." className="img-fluid" src={avatar} />
                </div>
              </div>
            </div>
            <h3 className="landing-yellow-tangerine font-weight-bold mt-3">
              Thomas McGarvey
            </h3>
            <span className="mt-1 mb-4 px-4 py-1 h-auto badge badge-neutral-warning text-shadow">
              Software Engineer
            </span>
          </div>
          <div className="rounded card-style-4 card-highlight">
            <Tooltip arrow title="Linkedin">
              <a href="https://www.linkedin.com/in/thomas-mcgarvey/">
                <IconButton variant="outlined">
                  <img
                    className="dev-img-sm"
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linkedin/linkedin-original.svg"
                    alt=""
                  />
                </IconButton>
              </a>
            </Tooltip>
            <Tooltip arrow title="Github">
              <a href="https://github.com/ThomasMcGarvey">
                <IconButton variant="outlined">
                  <img
                    className="dev-img-sm"
                    src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg"
                    alt=""
                  />
                </IconButton>
              </a>
            </Tooltip>
          </div>
          <div className="pt-3 text-shadow">
            <List className="py-2">
              <ListItem button onClick={onBtnBlick}>
                <FontAwesomeIcon
                  color="white"
                  size="1x"
                  icon={faQuestionCircle}
                />
                <span id="about" className="px-3 text-white">
                  About
                </span>
              </ListItem>
              <ListItem button onClick={onBtnBlick}>
                <FontAwesomeIcon color="white" size="1x" icon={faFire} />
                <span id="skills" className="px-3 text-white">
                  Skills
                </span>
              </ListItem>
            </List>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default PersonalCard;
