import React, { useEffect, useState } from "react";
import { Button, Drawer } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTasks,
  faEnvelope,
  faHome,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { scroller } from "react-scroll";
import { useNavigate } from "react-router-dom";
import "../../App.css";

const NavBar = () => {

  const navigate = useNavigate();
  //==========( HANDLE SCROLL TO)

  const scrollToSection = (e) => {
    scroller.scrollTo(e.target.innerText.toLowerCase(), {
      duration: 500,
      delay: 0,
    });
  };

  //==========( NAVBAR STICKING )

  const [sticky, setSticky] = useState();
  const [state, setState] = useState({
    right: false,
  });
  window.onscroll = function () {
    catchNavBar();
  };

  // TODO: Disable when on home page
  function catchNavBar() {
    var navbar = document.getElementById("navbar");
    var sticky = navbar.offsetTop;
    if (window.pageYOffset >= sticky) {
      setSticky("sticky");
    } else {
      setSticky("");
    }
  }

  //==========( DRAWER )

  const toggleDrawer = (side, open) => () => {
    setState({ ...state, [side]: open });
  };

  //==========( HANDLE WINDOW RESIZE )

  const [nav, setNav] = useState();

  function handleResize() {
    let navOption = window.innerWidth > 600 ? "navLinks" : "navMenu";

    setNav(navOption);
  }

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
  });

  //==========( NAVIGATION )

  // TODO: Break out into single component
  const handleNav = (e) => {
    switch (e.target.id) {
      case "continue":
        navigate("/shoppingCart");
        break;
      case "update":
        navigate("/update");
        break;
      default:
        break;
    }
  };

  //==========( CONTENT )

  // TODO: Map nav links
  const navLinks = () => {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center text-white">
          <Button
            name="home"
            color="inherit"
            className="btn-inverse text-capitalize"
            onClick={scrollToSection}
          >
            <FontAwesomeIcon
              className="nav-icon-color"
              size="1x"
              icon={faHome}
            />
            <span className="px-3">Home</span>
          </Button>

          <Button
            color="inherit"
            className="btn-inverse text-capitalize"
            onClick={scrollToSection}
          >
            <FontAwesomeIcon
              className="nav-icon-color"
              size="1x"
              icon={faFolderOpen}
            />
            <span className="px-3">Portfolio</span>
          </Button>

          <Button
            color="inherit"
            className="btn-inverse text-capitalize"
            onClick={scrollToSection}
          >
            <FontAwesomeIcon
              className="nav-icon-color"
              size="1x"
              icon={faTasks}
            />
            <span className="px-3">Projects</span>
          </Button>

          <Button
            color="inherit"
            className="btn-inverse text-capitalize"
            onClick={scrollToSection}
          >
            <FontAwesomeIcon
              className="nav-icon-color"
              size="1x"
              icon={faEnvelope}
            />
            <span className="px-3">Contact</span>
          </Button>

          <Button
            id="continue"
            color="inherit"
            className="btn-inverse text-capitalize"
            onClick={handleNav}
          >
            <FontAwesomeIcon
              id="continue"
              className="nav-icon-color"
              size="1x"
              icon={faTasks}
            />
            <span id="continue" className="px-3">Personal Projects</span>
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const navMenu = () => {
    return (
      <React.Fragment>
        <div className="d-flex justify-content-center text-white">
          <Button
            variant="text"
            onClick={toggleDrawer("top", true)}
            size="medium"
          >
            <FontAwesomeIcon
              className="landing-tangerine"
              size="2x"
              icon={faBars}
            />
          </Button>
        </div>
        <Drawer
          variant="temporary"
          anchor="top"
          open={state.top}
          onClose={toggleDrawer("top", false)}
          elevation={11}
        >
          {navLinks()}
        </Drawer>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <span id="navbar" />
      <div className={`card-style-2 ${sticky}`}>
        <div className={`text-center nav-bar-bg`}>
          <div className="header-nav-logo align-items-center d-flex justify-content-start">
            <div className="nav-logo"></div>
          </div>
          <div className="header-nav-menu d-lg-block ">
            {nav === "navLinks" ? navLinks() : navMenu()}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NavBar;
