import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  TextareaAutosize,
} from "@material-ui/core";

function Contact() {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={4}
        className="justify-content-center"
        data-aos="zoom-in"
        data-aos-once="true"
      >
        <Grid item xs={6} sm={6} md={6}>
          <Card className="card-style shadow text-white text-shadow">
            <CardContent className="p-3">
              <Grid item xs={12} sm={12} md={12} className="">
                <input
                  className="col-12"
                  placeholder="Name"
                  type="text"
                  name="name"
                  required=""
                />

                <input
                  className="col-12"
                  placeholder="Email"
                  type="email"
                  name="email"
                  required=""
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextareaAutosize
                  minRows={3}
                  className="col-12"
                  placeholder="Your Message - Under construction, please contact me through linkedin."
                />
              </Grid>
              <div className="col-12 justify-content-center text-center">
                <Button
                  className="mui-btn btn-style-1 text-white text-shadow"
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Contact;
