import React from "react";
import { Card } from "@material-ui/core";

function About() {
  return (
    <React.Fragment>
      <Card
        data-aos="flip-left"
        data-aos-once="true"
        data-aos-easing="ease-out"
        className="col-8 p-4 m-2 card-style text-shadow shadow"
      >
        <div className="p-2">
          <div className="pb-4 font-weight-bold text-white">About me:</div>
          <div className="font-weight-bold text-white">
            <p>
              "I am an army Veteran and a software engineer. I pride myself in
              creating beautiful web apps and am skilled at creating custom
              designs as well as wiring up pre-made components." <br /> <br />
              "I am fortunate to have had the oprotunity to participate in a
              full stack bootcamp and enjoyed every minute of it. I am motivated
              in creating quality products and love to learn new technologies"
            </p>
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default About;
