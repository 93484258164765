//import { actions } from "../../store/store"


  export const calculateTotal = (items, index, quantity) =>{
    let sum = 0;
    if (items) {
      let cartItems = items;
      let length = cartItems.length;

      for (let i = 0; i < length; i++) {
        //console.log("index", index, "quantity", quantity, "i", i);
        if( i === index ){
          sum =
            sum +
            Number(quantity) *
            Number(cartItems[i].productPrice);
        }
        else{
        sum =
          sum +
          Number(cartItems[i].productQuantity) *
            Number(cartItems[i].productPrice);
        }
      }
    }
    return sum;
  }