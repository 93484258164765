import { combineReducers } from "@reduxjs/toolkit";
import { cart } from "./utils/reducers";

export default combineReducers({
  cart: cart,
});

/*
export const rootReducer = combineReducers({
  cart: cart,
});
*/